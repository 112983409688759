import React, { useEffect, useState } from 'react';
import {
    Box,
    Container,
    Paper,
    Tab,
    Tabs,
    Typography,
    Button,
    Tooltip, Stack
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import {URL_TEACHER_SPECIFICATIONS_BASE, URL_TEACHER_UNIT} from "../../../router/urls";
import { Unit } from "../../../services/Unit/unit.model";
import { unitService } from "../../../services/Unit/unit.service";
import DataTable from "../../Common/DataTable";
import {AppUiObjectNames} from "../../../types/AppObjectNames";
import {PlusOne} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

const generateUnitLink = (unitId: string) => {
    const unitUrl = `${URL_TEACHER_UNIT}/${unitId}`;
    return (
        <Button variant="contained" href={unitUrl} style={{ minWidth: '150px' }}>{unitId}</Button>
    );
};

const convertTimestamp = (timestamp?: number): string => {
    return timestamp ? new Date(timestamp * 1000).toDateString() : 'N/A';
};

const ActiveExpiredUnitTable: React.FC = () => {
    const [activeUnits, setActiveUnits] = useState<Unit[]>([]);
    const [expiredUnits, setExpiredUnits] = useState<Unit[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedTab, setSelectedTab] = useState<number>(0);

    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const fetchedUnits = await unitService.list();
                setActiveUnits(fetchedUnits.active);
                setExpiredUnits(fetchedUnits.expired);
            } catch (error) {
                setError('Error fetching units');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchUnits();
    }, []);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    const processUnits = (units: Unit[]) => {
        return units.map(unit => ({
            id: unit.id,
            summaryName: unit.summary.name,
            lmsIntegrationName: unit?.lms_integration?.lms_connection?.name || 'N/A',
            summaryDescription: unit.summary.description || 'N/A',
            creationTimestamp: unit.creation_timestamp,
            workspaceExpires: unit.workspace_settings?.expires
        }));
    };

    const renderTable = (units: Unit[]) => {
        return (
            <Paper
                sx={{
                    marginTop: 2,
                    paddingX: 2,
                    paddingBottom: 2
                }}
            >
                <Stack
                    alignItems={"center"}
                    direction={"row"}
                    gap={1}
                    paddingTop={1}
                >
                    <AssignmentIcon fontSize={"large"}/>
                    <Typography
                        variant={"h5"}
                        component={"h2"}
                    >
                        Your {AppUiObjectNames.UNIT.toString()}s
                    </Typography>
                </Stack>
                <DataTable
                    columns={newColumns}
                    data={processUnits(units)}
                    showSearch={true}
                    getRowId={(row) => row.id}
                    loading={loading}
                    showContainer={true}
                    checkboxSelection={false}
                />
            </Paper>
        );
    };

    if (error) return <Typography color="error">{error}</Typography>;

    const newColumns = [
        {
            field: 'id',
            headerName: 'ID',
            renderCell: (params: { row: any; }) => generateUnitLink(params.row.id),
            minWidth: 170
        },
        {
            field: 'summaryName',
            headerName: 'Name',
            renderCell: (params: { value: any; }) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
        {
            field: 'lmsIntegrationName',
            headerName: 'LMS Course',
            minWidth: 100,
            renderCell: (params: { value: any; }) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
        {
            field: 'summaryDescription', headerName: 'Description', minWidth: 300, renderCell: (params: { value: any; }) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            )
        },
        {
            field: 'creationTimestamp',
            headerName: 'Created',
            type: 'dateTime',
            valueFormatter: (value?: number) => {
                return convertTimestamp(value)
            }
        },
        {
            field: 'workspaceExpires',
            headerName: 'Expires',
            type: 'dateTime',
            valueFormatter: (value?: number) => {
                return convertTimestamp(value)
            }
        },
    ];

    return (
        <Container>
            <Box>
                <Tabs
                    variant={"scrollable"}
                    value={selectedTab}
                    onChange={handleTabChange}
                >
                    <Tab label={`Active ${AppUiObjectNames.UNIT}s`} />
                    <Tab label={`Expired ${AppUiObjectNames.UNIT}s`} />
                </Tabs>
                <Button
                    color={"primary"}
                    href={URL_TEACHER_SPECIFICATIONS_BASE}
                    variant={"contained"}
                    startIcon={<AddIcon />}
                    sx={{
                        mt: 2
                    }}
                >
                    Create New
                </Button>
                {selectedTab === 0 && renderTable(activeUnits)}
                {selectedTab === 1 && renderTable(expiredUnits)}
            </Box>
        </Container>
    );
};

export default ActiveExpiredUnitTable;
